import {
    SUBMIT_BOOKING_ENQUIRY,
    SUBMIT_BOOKING_ENQUIRY_FAILED,
    RESET_FORMS
} from '../actions/types'

let initialState = {
    success: null,
    errors: null
}

const submitEnquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_BOOKING_ENQUIRY:
            return {...state, success: true, errors: null}
        case SUBMIT_BOOKING_ENQUIRY_FAILED:
            return {...state, success: false, errors: action.payload}
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default submitEnquiryReducer