import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import HeroBanner from './views/HeroBanner'
import SplitColumnSection from './ui/SplitColumnSection'
import img1 from '../assets/img/home/facilities@2x.jpg'
import img2 from '../assets/img/home/gallery@2x.jpg'
import {Link} from 'react-router-dom'
import EnquireNowSection from './views/EnquireNowSection'

class Home extends React.Component {

    scrollToPosition = (anchorTag, space) => {
        setTimeout(() => {
            const element = document.querySelector(anchorTag)
            if(element){
                let topPos = element.getBoundingClientRect().top + window.pageYOffset - 100
                if(space){
                    topPos = element.getBoundingClientRect().top + window.pageYOffset - space
                } 
                
                window.scrollTo({
                    top: topPos, // scroll so that the element is at the top of the view
                    behavior: 'smooth' // smooth scroll
                })
            }
        }, 300);
    }

    render(){

        const data = [
            {
                preview_image: img1,
                content: (
                    <>
                        <h2 className='_heading-3 _gold'>Facilities</h2>
                        <p>Situated just 10km from the picturesque town of Robertson, the Skills Centre’s state-of-the-art facilities can accommodate a range of group configurations. Up to 100 delegates can be accommodated in the Auditorium, while smaller groups of up to 20 people can be hosted in various training rooms. All venues at the Skills Centre are wheelchair friendly. Please see detailed descriptions of the various venue configurations below. We invite you to experience our hospitality first-hand and be inspired!</p>
                        <Link to="/facilities" className="_button">View More</Link>
                    </>
                )
            },
            {
                preview_image: img2,
                content: (
                    <>
                        <h2 className='_heading-3 _gold'>Gallery</h2>
                        <p>View images of past Events here.</p>
                        <Link to="/gallery" className="_button">View More</Link>
                    </>
                )
            }
        ]

        return(
            <Layout>
                <Helmet>
                    <title>Graham and Rhona Beck Skills Centre</title>
                    <meta name="description" content="The Graham and Rhona Beck Skills Centre was originally established in 2007 as a broad-based training and education facility for farm workers in the area. The Beck family, the founders of the centre and its namesake, envisioned it as a hub for community development and social upliftment. Today, the Skills Centre is open to the public as a modern and attractive conferencing and training facility." />
                    <link rel="canonical" href="https://beckfamilyconferencecentre.co.za/" />
                </Helmet>
                <ScrollToTop />
                <HeroBanner scrollDown={() => this.scrollToPosition("#_home-2", 65)} />
                <section className='_section _section-general _section-home-history' id="_home-2">
                    <div className='_grid'>
                        <div className='_column'>
                            <h2 className='_heading-5'>HISTORY</h2>
                            <h3 className='_heading-3 _gold'>About Us</h3>
                            <p>The Graham and Rhona Beck Skills Centre was originally established in 2007 as a broad-based training and education facility for farm workers in the area. The Beck family, the founders of the centre and its namesake, envisioned it as a hub for community development and social upliftment. Today, the Skills Centre is open to the public as a modern and attractive conferencing and training facility.</p>
                            <p>Offering state-of-the-art amenities and a tranquil, nurturing group setting in the heart of the Langeberg, the Skills Centre is a space for educational groups, corporate cohorts, and more to commune, converse, and develop skills and competencies.</p>
                            <p>As a registered Trust, the income generated from renting the facilities contributes directly to providing philanthropic activities to the rural communities of the Langeberg Municipal area. In this way, the Becks’ vision of creating the centre as a space for the upliftment of the surrounding communities continues to be honoured.</p>
                            <p className='_heading-4 _gold'>Come experience our hospitality, build community, and be inspired!</p>
                        </div>
                    </div>
                </section>
                <SplitColumnSection data={data} />
                <EnquireNowSection />
            </Layout>
            
        )
    }
}

export default Home