import React from 'react'
import {Link} from 'react-router-dom'

class TopNavRight extends React.Component {
    
    render(){
        return(
            <ul className='_nav-list _nav-list-right'>
                <li className='_nav-item'>
                    <Link to="/location" className="_nav-link">LOCATION</Link>
                </li>
                <li className='_nav-item'>
                    <Link to="/contact" className="_nav-link">CONTACT US</Link>
                </li>
            </ul>
        )
    }
}

export default TopNavRight