import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'
import {
    Input
} from './fields'

class NewsletterSubscriptionForm extends React.Component{

    state= {
        button: 'Submit'
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.setState({
            button: 'Submitting'
        })
        this.props.onSubmit(formValues)
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Submitting'){
                this.setState({
                    button: 'Submit'
                })
            }            
        }

        return(
            <form id="newsletter-subscription" className="_form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field name="full_name" type="text" label="First Name" component={Input} />
                    <Field name="email" type="email" label="Email Address" component={Input} />
                    <div className="_form-group" style={{width: 'auto'}}>
                        <br />
                        <button className={`_button _submit-button ${this.state.button === 'Submitting' ? '_sending' : ''}`}>{this.state.button}</button> 
                    </div>                   
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {
    const errors = {}

    if(!formValues.full_name){
        errors.full_name = "You have to enter your first name"
    }

    if(!formValues.email){
        errors.email = "Please enter email address"
    }

    return errors
}

export default reduxForm({
    form: 'newsletter-subscription',
    validate
})(NewsletterSubscriptionForm)