import React from 'react'
import {Link} from 'react-router-dom'
import { formValues } from 'redux-form'
import logo2 from '../../assets/img/home/beck-family-logo.svg'
import NewsletterSubscriptionForm from '../forms/NewsletterSubscriptionForm'
import {
    newsletterSubscription,
    resetForms
} from '../../actions'
import {connect} from 'react-redux'
import history from '../../history'

class Footer extends React.Component {

    onSubmit = formValues => {
        this.props.newsletterSubscription(formValues)
    }
    
    render(){

        if(this.props.submitState.success){
            history.push('/subscribe-to-newsletter/thankyou')
            this.props.resetForms()
        }
        const date = new Date()
        return(
            <footer className='_footer'>
                <div className='_grid'>
                    <div className='_row'>
                        <div className='_item'>
                            <div className='_row'>
                                <ul className='_footer-list'>
                                    <li className='_footer-list-item'>
                                        <Link to="/" className='_footer-link _gold'>HOME</Link>
                                    </li>
                                    <li className='_footer-list-item'>
                                        <Link to="/facilities" className='_footer-link _gold'>FACILITIES</Link>
                                    </li>
                                    <li className='_footer-list-item'>
                                        <Link to="/gallery" className='_footer-link _gold'>GALLERY</Link>
                                    </li>
                                    <li className='_footer-list-item'>
                                        <Link to="/contact" className='_footer-link _gold'>CONTACT US</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='_row'>
                                <div className='item'>
                                    <p className='_light-text' style={{margin: '0'}}>
                                        <a href="tel:0236261833">023 626 1833</a> | <a href="mailto:enquiries@beckfamilyestates.co.za">enquiries@beckfamilyestates.co.za</a><br />
                                        <a href="https://goo.gl/maps/5b1R1nNMx1FKtet99" target="_blank" rel="noreferrer">R60, Riverside/Goree Rd, Robertson, 6705</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='_item _newsletter-item'>
                            <p className='_gold' style={{margin: '0 0 15px 0'}}><strong>Join the Community</strong></p>
                            <p className='_par-big' style={{margin: '15px 0 0 0'}}>Suscribe to our newsletter for all information on events and latest news.</p>
                            <NewsletterSubscriptionForm 
                                onSubmit={this.onSubmit}  
                                errors={this.props.submitState.errors ? this.props.submitState.errors : null}  
                            />
                        </div>
                        
                    </div>
                    <div className='_row _row-2'>
                        <Link to="/" className='_logo-3'>
                            <img src={logo2} alt="Beck Family" />
                        </Link>
                        {/* <ul className='_footer-list'>
                            <li className='_footer-list-item'>
                                <Link to="/terms-of-access" className='_footer-link'>Terms of Access</Link>
                            </li>
                            <li className='_footer-list-item'>
                                <Link to="/popia" className='_footer-link'>POPIA</Link>
                            </li>
                        </ul> */}
                    </div>
                    <div className='_row'>
                        <div className='_item' style={{width: '100%'}}>
                            <p className='_light-text _copyright'>All original content © {date.getFullYear()} Skillscentre. Powered by <a href="https://www.optimalonline.co.za/" target="_Blank" rel="noreferrer">Optimal Online</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
const mapStateToProps = state => {
    return {
        submitState: state.newsletterSubscriptionState
    }
}

export default connect(mapStateToProps, {
    newsletterSubscription,
    resetForms
})(Footer)