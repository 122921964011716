import React from 'react'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'
import history from '../history'

//public
import Home from './Home'
import Facilities from './Facilities'
import Booking from './Booking'
import Gallery from './Gallery'
import Location from './Location'
import Contact from './Contact'
import BookingThankyou from './BookingThankyou'
import ContactThankyou from './ContactThankyou'
import NewsletterSubscriptionThankyou from './NewsletterSubscriptionThankyou'
import PageNotFound from './PageNotFound'

class App extends React.Component {

    render(){
        return(
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/facilities" component={Facilities} />
                    <Route exact path="/make-a-booking" component={Booking} />
                    <Route exact path="/make-a-booking/thankyou" component={BookingThankyou} />
                    <Route exact path="/gallery" component={Gallery} />
                    <Route exact path="/location" component={Location} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/contact/thankyou" component={ContactThankyou} />
                    <Route exact path="/subscribe-to-newsletter/thankyou" component={NewsletterSubscriptionThankyou} />
                    <Route exact component={PageNotFound} /> 
                </Switch>
            </Router>
        )
    }
}

export default App