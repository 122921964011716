import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input
} from './fields'

class CompanyInvoicingDetailsForm extends React.Component {

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    onCancel = () => {
        this.props.clear()
    }

    render(){

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-booking">
                <div className="_form-row">
                    <Field name="company_name" type="text" component={Input} label="Name of Company:*" />
                    <Field name="contact_person" type="text" component={Input} label="Contact Person:*" />
                    <Field name="tel" type="text" component={Input} label="Tel no:*" />
                </div>
                <div className="_form-row">
                    <Field name="email" type="email" component={Input} label="Email Address:*" />
                    <Field name="postal_address" type="text" component={Input} label="Postal Address:*" />
                    <Field name="fax" type="text" component={Input} label="Fax no: (optional)" />
                </div>
                <div className="_form-row">
                    <Field name="company_vat_no" type="text" component={Input} label="Company VAT no: (optional)" />
                </div>
                <div className="_form-row _button-row">
                    {this.props.initialValues.company_name && <button className='_button _button-3' type="button" onClick={this.onCancel}>Cancel</button>}
                    <button className='_button'>Next</button>
                </div>
            
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.company_name){
        errors.company_name = 'You must enter your name of company'
    }
    if(!formValues.contact_person){
        errors.contact_person = 'You must enter a contact person'
    }
    if(!formValues.tel){
        errors.tel = 'You must enter your telephone number'
    }
    if(!formValues.email){
        errors.email = 'You must enter your email address'
    }
    if(!formValues.postal_address){
        errors.postal_address = 'You must enter your postal address'
    }

    return errors
}

export default reduxForm({
    form: 'companyinvoicingdetails',
    validate
})(CompanyInvoicingDetailsForm)