import React from 'react'
import HeaderInitial from './HeaderInitial'
import Header from './Header'
import SideNav from './SideNav'
import Footer from './Footer'

class Layout extends React.Component {

    state = {
        headerTransform: false,
        menuOpen: false
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleTransform, { capture: true })
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleTransform, { capture: true })
    }

    handleTransform = () => {

        let y = window.scrollY
        
        if(y > 300){
            if(!this.state.headerTransform){
                this.setState({
                    headerTransform: !this.state.headerTransform
                })
            }
        } else {
            if(this.state.headerTransform){
                this.setState({
                    headerTransform: !this.state.headerTransform
                })
            }
        }
    }

    onToggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }
    
    render(){
        return(
            <>
                <HeaderInitial 
                    onClick={this.onToggleMenu} 
                    menuOpen={this.state.menuOpen}
                />
                <Header 
                    onClick={this.onToggleMenu} 
                    menuOpen={this.state.menuOpen} 
                    headerTransform={this.state.headerTransform} 
                />
                <SideNav menuOpen={this.state.menuOpen} />                
                <div className='_page-content'>
                    {this.props.children}
                </div>
                <Footer />
            </>
        )
    }
}

export default Layout