import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input
} from './fields'

class CateringsForm extends React.Component {

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    onCancel = () => {
        this.props.clear()
    }

    render(){

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-booking">

                <div className="_form-row">
                    <Field name="expected_arrival_of_delegates" type="time" component={Input} label="Expected arrival of delegates" />
                    <Field name="expected_arrival_of_delegates_no_of_delegates" type="number" component={Input} placeholder="No of delegates" />
                </div>

                <div className="_form-row">
                    <Field name="event_commence" type="time" component={Input} label="Event commence" />
                    <Field name="event_commence_no_of_delegates" type="number" component={Input} placeholder="No of delegates" />
                </div>

                <div className="_form-row">
                    <Field name="mid_morning_brake" type="time" component={Input} label="Mid-morning brake" />
                    <Field name="mid_morning_brake_no_of_delegates" type="number" component={Input} placeholder="No of delegates" />
                </div>

                <div className="_form-row">
                    <Field name="lunch" type="time" component={Input} label="Lunch" />
                    <Field name="lunch_no_of_delegates" type="number" component={Input} placeholder="No of delegates" />
                </div>

                <div className="_form-row">
                    <Field name="afternoon_break" type="time" component={Input} label="Afternoon break" />
                    <Field name="afternoon_break_no_of_delegates" type="number" component={Input} placeholder="No of delegates" />
                </div>

                <div className="_form-row">
                    <Field name="end_of_day" type="time" component={Input} label="End of day" />
                    <Field name="end_of_day_no_of_delegates" type="number" component={Input} placeholder="No of delegates" />
                </div>

                <p><strong>Special dietary requirements</strong> – Indicate no of delegates in the appropriate requirement</p>

                <div className="_form-row">
                    <Field name="halaal" type="number" component={Input} label="Halaal" />
                    <Field name="vegan" type="number" component={Input} label="Vegan" />
                    <Field name="vegetarian" type="number" component={Input} label="Vegetarian" />
                </div>

                <div className="_form-row">
                    <Field name="kosher" type="number" component={Input} label="Kosher" />
                    <Field name="gluten_free" type="number" component={Input} label="Gluten Free" />
                </div>

                <div className="_form-row">
                    <Field name="allergies" type="text" component={Input} label="Allergies" />
                </div>
                
                <div className="_form-row _button-row">
                    <button className='_button _button-3' type='button' onClick={this.props.previous}>Back</button>
                    <button className='_button _button-3' type="button" onClick={this.onCancel}>Cancel</button>
                    <button className='_button'>Next</button>
                </div>

            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    return errors
}

export default reduxForm({
    form: 'caterings',
    validate
})(CateringsForm)