import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    Checkbox
} from './fields'

class VenueRequirementsForm extends React.Component {

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    onCancel = () => {
        this.props.clear()
    }

    render(){

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-booking">
                <div className="_form-row">
                    <Field name="event_date_from" type="date" component={Input} label="Select Start Date*" />
                    <Field name="event_date_to" type="date" component={Input} label="Select End Date*" />
                    <Field name="amount_of_days" type="number" component={Input} label="Total no of days*"  />
                </div>
                <div className="_form-row">
                    <Field name="time_from" type="time" component={Input} label="Start Time*" />
                    <Field name="time_to" type="time" component={Input} label="Finish At*" />
                </div>
                <div className="_form-row">
                    <Field name="no_of_delegates" type="number" component={Input} label="No of Delegates*" />
                    <p>Note terms and conditions of payment Point no 5.</p>
                </div>
                <div className="_form-row">
                    <Field 
                        component={Checkbox} 
                        name="venue_layout_ushape" 
                        // value="1" 
                        label="U-shape" 
                        checked={this.props.initialValues.venue_layout_ushape && this.props.initialValues.venue_layout_ushape} 
                    />
                    <Field 
                        component={Checkbox} 
                        name="venue_layout_workshop" 
                        // value="1" 
                        label="Workshop" 
                        checked={this.props.initialValues.venue_layout_workshop && this.props.initialValues.venue_layout_workshop} 
                    />
                    <Field 
                        component={Checkbox} 
                        name="venue_layout_cinema" 
                        // value="1" 
                        label="Cinema/Auditorium" 
                        checked={this.props.initialValues.venue_layout_cinema && this.props.initialValues.venue_layout_cinema} 
                    />
                </div>
                <div className="_form-row">
                    <Field 
                        component={Checkbox} 
                        name="equipment_data_projector_screen" 
                        // value="1" 
                        label="Data projector and screen" 
                        checked={this.props.initialValues.equipment_data_projector_screan && this.props.initialValues.equipment_data_projector_screan}
                    />
                    <Field 
                        component={Checkbox} 
                        name="equipment_flip_charts" 
                        // value="1" 
                        label="Flip chart(s)" 
                        checked={this.props.initialValues.equipment_flip_charts && this.props.initialValues.equipment_flip_charts}
                    />
                    <Field component={Input} name="equipment_other" label="Other special equipment (optional)" />
                </div>
                
                <div className="_form-row _button-row">
                    <button className='_button _button-3' type='button' onClick={this.props.previous}>Back</button>
                    <button className='_button _button-3' type="button" onClick={this.onCancel}>Cancel</button>
                    <button className='_button'>Next</button>
                </div>
                
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    // const blockedFullDays = [
    //     20221225,
    //     20230101,
    // ]

    // const blockedHalfDays = [
    //     20221224,
    //     20221231,
    // ]

    // parseInt(formValues.event_date_from.replaceAll('-', ''))

    if(!formValues.event_date_from){
        errors.event_date_from = 'You must add start date'
    }

    if(!formValues.event_date_to){
        errors.event_date_to = 'You must add end date'
    }

    if(!formValues.amount_of_days){
        errors.amount_of_days = 'You must enter a amount_of_days'
    }

    if(!formValues.time_from){
        errors.time_from = 'You must enter start time'
    }

    if(!formValues.time_to){
        errors.time_to = 'You must enter end time'
    }

    if(!formValues.no_of_delegates){
        errors.no_of_delegates = 'You must enter a number of delegates'
    }

    //temp error handeling
    // if(formValues.event_date_from && formValues.event_date_to){
    //     for (let i = 0; i < blockedFullDays.length; i++) {
    //         if(blockedFullDays[i] > parseInt(formValues.event_date_from.replaceAll('-', '')) && blockedFullDays[i] < parseInt(formValues.event_date_to.replaceAll('-', ''))){
    //             errors.event_date_from = `The tasting room will be closed on 25 Dec 2022 and 1 Jan 2023`
    //         }
    //     }
    // }
    
    // if(formValues.event_date_from && formValues.event_date_to){
    //     for (let i = 0; i < blockedHalfDays.length; i++) {
    //         if(blockedHalfDays[i] > parseInt(formValues.event_date_from.replaceAll('-', '')) && blockedHalfDays[i] < parseInt(formValues.event_date_to.replaceAll('-', ''))){
    //             if(parseInt(formValues.time_from) >= 13){
    //                 errors.time_from = 'Tasting Room will closed at 13:00 on 24 and 31 December 2022'
    //             }
    //             if(parseInt(formValues.time_to) >= 13){
    //                 errors.time_to = 'Tasting Room will closed at 13:00 on 24 and 31 December 2022'
    //             }
    //         }
    //     }
    // }
    
    // if(formValues.event_date_from){
    //     if(blockedFullDays.includes(parseInt(formValues.event_date_from.replaceAll('-', '')))) {
    //         errors.event_date_from = `The tasting room will be closed on ${formValues.event_date_from}`
    //     }
    // }
    
    // if(formValues.event_date_to){
    //     if(blockedFullDays.includes(parseInt(formValues.event_date_to.replaceAll('-', '')))) {
    //         errors.event_date_to = `The tasting room will be closed on ${formValues.event_date_to}`
    //     }
    // }
    
    // if(formValues.event_date_from && formValues.event_date_to && formValues.time_from && formValues.time_to)
    // if(blockedHalfDays.includes(parseInt(formValues.event_date_from.replaceAll('-', ''))) || blockedHalfDays.includes(parseInt(formValues.event_date_to.replaceAll('-', '')))) {
    //     if(parseInt(formValues.time_from) >= 13){
    //         errors.time_from = 'Tasting Room will closed at 13:00 on 24 and 31 December 2022'
    //     }
    //     if(parseInt(formValues.time_to) >= 13){
    //         errors.time_to = 'Tasting Room will closed at 13:00 on 24 and 31 December 2022'
    //     }
    // }

    return errors
}

export default reduxForm({
    form: 'venuerequirements',
    validate
})(VenueRequirementsForm)