import React from 'react'

class SplitColumnSection extends React.Component{

    renderColumns = () => {
        return this.props.data.map(({preview_image,content}, index) => {
            return (
                <div className='_split-grid' key={index}>
                    <div className='_split-column' style={{
                        backgroundImage: `url(${preview_image})`
                    }}>

                    </div>
                    <div className='_split-column'>
                        <div className='_panel'>
                            {content}
                        </div>
                    </div>
                </div>
            )
        })
    }

    render(){
        return(
            <section className='_section _section-split-column'>
                {this.renderColumns()}
            </section>
        )
    }
}

export default SplitColumnSection