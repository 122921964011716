import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import EnquireNowSection from './views/EnquireNowSection'
import Banner from './ui/Banner'
import bannerImg from '../assets/img/facilities/facilities-banner.jpg'
import VenueItems from './views/VenueItems'

import img1 from '../assets/img/facilities/Conferencing-Venues/AUDITORIUM-CONFERENCE-ROOM-1/01-conference-room-one@2x.png'
import img2 from '../assets/img/facilities/Conferencing-Venues/AUDITORIUM-CONFERENCE-ROOM-1/02-conference-room-one@2x.png'
import img3 from '../assets/img/facilities/Conferencing-Venues/AUDITORIUM-CONFERENCE-ROOM-1/03-conference-room-one@2x.png'
import img4 from '../assets/img/facilities/Conferencing-Venues/AUDITORIUM-CONFERENCE-ROOM-1/04-conference-room-one@2x.png'

import img5 from '../assets/img/facilities/Conferencing-Venues/CONFERENCE-ROOM-2/01-conference-room-two@2x.jpg'
import img6 from '../assets/img/facilities/Conferencing-Venues/CONFERENCE-ROOM-2/02-conference-room-two@2x.png'
import img7 from '../assets/img/facilities/Conferencing-Venues/CONFERENCE-ROOM-2/03-conference-room-two@2x.png'
import img8 from '../assets/img/facilities/Conferencing-Venues/CONFERENCE-ROOM-2/04-conference-room-two@2x.png'

import img9 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-1/01-lecture-room-one@2x.png'
import img10 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-1/02-lecture-room-one@2x.png'
import img11 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-1/03-lecture-room-one@2x.png'
import img12 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-1/04-lecture-room-one@2x.png'

import img13 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-2/01-lecture-room-two@2x.png'
import img14 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-2/02-lecture-room-two@2x.png'
import img15 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-2/03-lecture-room-two@2x.png'
import img16 from '../assets/img/facilities/Conferencing-Venues/LECTURE-ROOM-2/04-lecture-room-two@2x.png'

class Facilities extends React.Component {

    scrollToPosition = (anchorTag, space) => {
        setTimeout(() => {
            const element = document.querySelector(anchorTag)
            if(element){
                let topPos = element.getBoundingClientRect().top + window.pageYOffset - 100
                if(space){
                    topPos = element.getBoundingClientRect().top + window.pageYOffset - space
                } 
                
                window.scrollTo({
                    top: topPos, // scroll so that the element is at the top of the view
                    behavior: 'smooth' // smooth scroll
                })
            }
        }, 300);
    }

    renderVenueItems = data => {
        return data.map((item, index) => {
            return (
                <VenueItems data={item} key={index} />
            )
        })
    }

    render(){

        const data = [
            {
                name: "AUDITORIUM / CONFERENCE ROOM 1",
                images: [img1, img2, img3, img4],
                description: (
                    <ul className='_unordered-list'>
                        <li>Seats 100 people</li>
                        <li>Configuration is set</li>
                        <li>Audio system & DVD player</li>
                        <li>Podium with microphone</li>
                        <li>1 roaming microphone</li>
                        <li>Air-conditioning</li>
                        <li>Data projector and screen</li>
                        <li>Flipcharts upon request</li>
                        <li>Catering provided in the Atrium and/or Cafeteria</li>
                    </ul>
                )
            },
            {
                name: "CONFERENCE ROOM 2",
                images: [img5, img6, img7, img8],
                description: (
                    <ul className='_unordered-list'>
                        <li>Custom configuration available (U-shape, cinema or workshop styles)</li>
                        <li>Seats max 30 people in U-shape style</li>
                        <li>Seats max 50 people in cinema or workshop style</li>
                        <li>Rooms set up with flipchart, mobile data projector and screen</li>
                        <li>Air conditioning</li>
                        <li>Water dispenser and glasses</li>
                        <li>This room may also serve as a break-away room to the Auditorium</li>
                    </ul>
                )
            },
            {
                name: "LECTURE ROOM 1",
                images: [img9, img10, img11, img12],
                description: (
                    <ul className='_unordered-list'>
                        <li>Custom configuration available (U-shape, cinema or workshop styles)</li>
                        <li>Seats max 15 people in U-shape style</li>
                        <li>Seats max 20 people in cinema or workshop style</li>
                        <li>Rooms set up with flipchart, data projector and screen</li>
                        <li>Water dispenser and glasses</li>
                        <li>Air-conditioning</li>
                        <li>Could be linked to Van Loveren room to accommodate up to 40 people in workshop style</li>
                        <li>This room may also serve as a break-away room to the Auditorium</li>
                    </ul>
                )
            },
            {
                name: "LECTURE ROOM 2",
                images: [img13, img14, img15, img16],
                description: (
                    <ul className='_unordered-list'>
                        <li>Custom configuration available (U-shape, cinema or workshop styles)</li>
                        <li>Seats max 15 people in U-shape style</li>
                        <li>Seats max 20 people in cinema or workshop style</li>
                        <li>Rooms set up with flipchart, data projector and screen</li>
                        <li>Water dispenser and glasses</li>
                        <li>Air conditioning</li>
                        <li>Could be linked to Robertson Winery room to accommodate up to 40 people in workshop style</li>
                        <li>This room may also serve as a break-away room to the Auditorium</li>
                    </ul>
                )
            }
        ]

        return(
            <Layout>
                <Helmet>
                    <title>Facilities | Graham and Rhona Beck Skills Centre</title>
                    <meta name="description" content="Situated just 10km from the picturesque town of Robertson, the Skills Centre’s state-of-the-art facilities can accommodate a range of group configurations. Up to 100 delegates can be accommodated in the Auditorium, while smaller groups of up to 20 people can be hosted in various training rooms. All venues at the Skills Centre are wheelchair friendly. Please see detailed descriptions of the various venue configurations below. We invite you to experience our hospitality first-hand and be inspired!" />
                    <link rel="canonical" href="https://beckfamilyconferencecentre.co.za/facilities" />
                </Helmet>
                <ScrollToTop />
                <Banner heading="Facilities" image={bannerImg} />
                <section className='_section _section-facilities'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <p>Situated just 10km from the picturesque town of Robertson, the Skills Centre’s state-of-the-art facilities can accommodate a range of group configurations. Up to 100 delegates can be accommodated in the Auditorium, while smaller groups of up to 20 people can be hosted in various training rooms. All venues at the Skills Centre are wheelchair friendly. Please see detailed descriptions of the various venue configurations below. We invite you to experience our hospitality first-hand and be inspired!</p>
                            </div>
                        </div>
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                            <nav className='_nav-facilities'>
                                <ul>
                                    <li>
                                        <button onClick={() => this.scrollToPosition("#converence-venues", 65)}>CONFERENCING VENUES</button>
                                    </li>
                                    <li>
                                        <div className='_vl' />
                                    </li>
                                    <li>
                                        <button onClick={() => this.scrollToPosition("#venue-hiring", 65)}>VENUE HIRING</button>
                                    </li>
                                    <li>
                                        <div className='_vl' />
                                    </li>
                                    <li>
                                        <button onClick={() => this.scrollToPosition("#catering", 65)}>CATERING</button>
                                    </li>
                                    <li>
                                        <div className='_vl' />
                                    </li>
                                    <li>
                                        <button onClick={() => this.scrollToPosition("#accomodation", 65)}>ACCOMMODATION</button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="converence-venues" className='_section _section-facility'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_heading-3 _gold'>Conferencing Venues</h2>
                            </div>
                        </div>
                    </div>
                    {this.renderVenueItems(data)}
                </section>
                <section id="venue-hiring" className='_section _section-facility'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_heading-3 _gold'>Venue Availability</h2>
                                Operational hours are between 07h30-16h30 daily (Monday-Friday) <br />
                                Not available for social functions e.g. weddings, birthday parties etc. <br />
                                Closed on Public Holidays <br />
                                Closed from 16 Dec – 2nd week of Jan for Annual Holidays
                            </div>
                        </div>
                    </div>
                </section>
                <section id="catering" className='_section _section-facility'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_heading-3 _gold'>Catering</h2>
                                <p>An independent company operates from the Graham & Rhona Beck Skills Centre and provides an in-house catering service to all patrons utilising our venues. Menus are designed to your specification and the cost is included in the quote of the venue cost. We regret no outside catering is permitted.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="accomodation" className='_section _section-facility'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_heading-3 _gold'>Accommodation</h2>
                                <p>
                                    Unfortunately, we do not currently have accommodation available on the premises. Please contact our local tourism association to assist you with a list of accommodation options in and around Robertson
                                </p>

                                <p>
                                    <strong className='_gold'>Robertson Tourism:</strong> <a href="tel:0236264437">023 626 4437</a> or <a href="mailto:info@robertson.org.za">info@robertson.org.za</a>.
                                </p>

                                <p>Additionally, our patrons have identified the accommodation below as their stay of choice:</p>
                                
                                <p>
                                    <strong className='_gold'>a) Closest to the Skills Centre:</strong><br />
                                    <ul className='_unordered-list'>
                                        <li>Wederom <a href="https://www.wederom.co.za" target="_blank" rel="noreferrer">www.wederom.co.za</a> (<a href="tel:0236264139">023 626 4139</a>)</li>
                                        <li>Little France <a href="https://www.littlefrance.co.za" target="_blank" rel="noreferrer">www.littlefrance.co.za</a> (<a href="tel:0236264174">023 626 4174</a>)</li>
                                        <li>Tierhoek Cottages <a href="https://www.tierhoekcottages.co.za" target="_blank" rel="noreferrer">www.tierhoekcottages.co.za</a> (<a href="tel:0236261191">023 626 1191</a>)</li>
                                        <li>Orange Grove Farm <a href="https://www.orangegrovefarm.co.za" target="_blank" rel="noreferrer">www.orangegrovefarm.co.za</a> (<a href="tel:0236266442">023 626 6442</a>)</li>
                                    </ul>
                                </p>

                                <p>
                                    <strong className='_gold'>b) In Robertson town</strong><br />
                                    The Robertson Small Hotel <a href="https://www.therobertsonsmallhotel.co.za" target="_blank" rel="noreferrer">www.therobertsonsmallhotel.co.za</a> (<a href="tel:0236267200">023 626 7200</a>)
                                </p>

                                <p>
                                    <strong className='_gold'>c) On Madeba Farm</strong><br />
                                    Grace House - <a href="tel:0746062789">(074) 606-2789</a><br />
                                    <a href="mailto:hello@stayatgracehouse.com" target="_blank" rel="noreferrer">hello@stayatgracehouse.com</a> - <a href="https://www.stayatgracehouse.com" target="_blank" rel="noreferrer">www.stayatgracehouse.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <EnquireNowSection />
            </Layout>
            
        )
    }
}

export default Facilities