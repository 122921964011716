import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import EnquireNowSection from './views/EnquireNowSection'
import Banner from './ui/Banner'
import image from '../assets/img/location/location-banner.jpg'
import pdf from '../assets/pdfs/Directions.pdf'

class Location extends React.Component {

    render(){

        return(
            <Layout>
                <Helmet>
                    <title>Location | Graham and Rhona Beck Skills Centre</title>
                    <meta name="description" content="Set amongst the vineyards in the peaceful countryside of the Breede River Winelands, the Graham and Rhona Beck Skills Centre provides the perfect backdrop for your conference or training initiative. Situated just 10km outside the picturesque town of Robertson, this modern, multipurpose facility can host up to 100 delegates in its Auditorium and smaller groups of up to 20 in various breakaway rooms." />
                    <link rel="canonical" href="https://beckfamilyconferencecentre.co.za/location" />
                </Helmet>
                <ScrollToTop />
                <Banner image={image} />
                <section className='_section _section-location'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel' style={{marginTop: '-200px'}}>
                                <h1 className='_heading-3 _gold' style={{textAlign: 'center'}}>Location</h1>
                                <div className='_iframe-continer'>
                                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53051.36003640901!2d19.82123778839949!3d-33.793986223311975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dd26fc672957ab3%3A0x38d8d8783ddb2470!2sGraham%20%26%20Rhona%20Beck%20Skills%20Centre!5e0!3m2!1sen!2sza!4v1649858528002!5m2!1sen!2sza" width="600" height="450" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                <div className='_row' style={{padding: "30px 0"}}>
                                    <div className='_item'>
                                        <p>
                                            <strong className='_gold'>Come visit us:</strong><br />
                                            <a href="https://goo.gl/maps/5b1R1nNMx1FKtet99" target="_blank" rel="noreferrer">R60, Riverside/Goree Rd, Robertson, 6705</a>
                                        </p>
                                        <p>
                                            <strong className='_gold'>Google Maps PLUS Code</strong><br />
                                            6Q4H+F3 Robertson
                                        </p>
                                        <p>-33.79317150320865 / 19.777794346025853</p>
                                    </div>
                                    <div className='_item'>
                                        <p>
                                            <strong className='_gold'>Call us:</strong><br />
                                            <a href="tel:0236261833">023 626 1833</a>  
                                        </p>
                                        <p>
                                            <strong className='_gold'>Email us:</strong><br />
                                            <a href="mailto:enquiries@beckfamilyestates.co.za">enquiries@beckfamilyestates.co.za</a>
                                        </p>
                                    </div>
                                </div>
                                <p style={{marginBottom: '0', marginTop: '0'}}>Set amongst the vineyards in the peaceful countryside of the Breede River Winelands, the Graham and Rhona Beck Skills Centre provides the perfect backdrop for your conference or training initiative. Situated just 10km outside the picturesque town of Robertson, this modern, multipurpose facility can host up to 100 delegates in its Auditorium and smaller groups of up to 20 in various breakaway rooms.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='_section _section-location'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <h2 className='_heading-3 _gold'>Directions</h2>
                                <p>Driving from Cape Town Take the N1 highway from Cape Town to Worcester (approximately 109kms). From Worcester, head east on the R60 towards Robertson. Follow the road for 35kms. Turn right onto the Riverside / Goree Road and the entrance to the building will be on the immediate righthand side of the road.</p>
                                <a href={pdf} download="Directions" className='_button' target="_blank" rel="noreferrer">Download Directions</a>
                            </div>
                        </div>
                    </div>
                </section>
                <EnquireNowSection />
            </Layout>
            
        )
    }
}

export default Location