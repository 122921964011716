import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
// import Form from './forms/BookingForm'
import CompanyInvoicingDetailsForm from './forms/CompanyInvoicingDetailsForm'
import VenueRequirementsForm from './forms/VenueRequirementsForm'
import CateringsForm from './forms/CateringsForm'
import SpecialAssistanceForm from './forms/SpecialAssistanceForm'
import ConfirmationForm from './forms/ConfirmationForm'
import {connect} from 'react-redux'
import history from '../history'

import Banner from './ui/Banner'
import image from '../assets/img/booking-form/booking-form-banner.jpg'
import icon from '../assets/img/booking-form/booking-icon.svg'

import Slider from "react-slick";
import {Link} from 'react-router-dom'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
    collectBookingEnquiryFormValues,
    submitBookingEnquiry,
    resetForms
} from '../actions'

class Booking extends React.Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state = {
            termsAccepted: false
        }
    }

    renderErrors = () => {
        return Object.values(this.props.submitState.errors).map((item, index) => {                
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    acceptTerms = () => {
        this.setState({
            termsAccepted: !this.state.termsAccepted
        })
    }

    scrollToPosition = (anchorTag, space) => {
        setTimeout(() => {
            const element = document.querySelector(anchorTag)
            if(element){
                let topPos = element.getBoundingClientRect().top + window.pageYOffset - 100
                if(space){
                    topPos = element.getBoundingClientRect().top + window.pageYOffset - space
                } 
                
                window.scrollTo({
                    top: topPos, // scroll so that the element is at the top of the view
                    behavior: 'smooth' // smooth scroll
                })
            }
        }, 150);
    }

    next() {
        this.slider.slickNext();
        this.scrollToPosition('#booking')
    }

    previous() {
        this.slider.slickPrev();
        this.scrollToPosition('#booking')
    }

    onSubmitNext = formValues => {
        this.props.collectBookingEnquiryFormValues(formValues)
        this.next()
        this.scrollToPosition('#booking')
    }

    onSubmit = () => {
        this.props.submitBookingEnquiry(this.props.enquiryState)
        this.next()
        this.scrollToPosition('#booking')
    }

    onCancel = () => {
        this.props.resetForms()
        window.location.reload()
    }

    render(){

        if(this.props.submitState.success){
            this.props.resetForms()
            history.push('/make-a-booking/thankyou')
        } else if(this.props.submitState.errors) {
            this.previous()
        }

        const settings = {
            dots: false,
            adaptiveHeight: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            easing: 'ease-in-out',
            draggable: false,
            swipe: false,
            touchMove: false,
        };

        return(
            <Layout>
                <Helmet>
                    <title>Booking Enquiry | Graham and Rhona Beck Skills Centre</title>
                    <meta name="description" content="VENUE BOOKING CONFIRMATION FORM - Please complete the form to confirm your booking." />
                    <link rel="canonical" href="https://beckfamilyconferencecentre.co.za/make-a-booking" />
                </Helmet>
                <ScrollToTop />
                <Banner image={image} />
                <section className='_section _section-enquire'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h1 className='_heading-3 _gold'>Enquire Now</h1>
                        </div>
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                           <div className='_panel' id="booking">
                               <div className='_headline-row'>
                                   <div className='_item'>
                                       <img src={icon} alt="" />
                                   </div>
                                   <div className='_item'>
                                        <h2 className='_gold _heading-6'>VENUE BOOKING CONFIRMATION</h2>
                                        <p>- Please complete form to confirm booking</p>
                                   </div>
                               </div>

                               {
                                    this.props.submitState.errors && (
                                        <div className="_errors-final">
                                            {this.renderErrors()}
                                        </div>
                                    )
                                }
                               
                               <div className='_form-slider'>
                                    
                                    <Slider ref={c => (this.slider = c)} {...settings}>
                                        <div className='_form-slide'>
                                            <h3 className='_heading-5 _gold'>STEP 1: COMPANY INVOICING DETAILS</h3>
                                            <CompanyInvoicingDetailsForm
                                                onSubmit={this.onSubmitNext}
                                                initialValues={this.props.enquiryState}
                                                clear={this.onCancel}
                                            />
                                        </div>
                                        <div className='_form-slide'>
                                            <h3 className='_heading-5 _gold'>STEP 2: VENUE REQUIREMENTS</h3>
                                            <VenueRequirementsForm 
                                                previous={this.previous} 
                                                onSubmit={this.onSubmitNext}
                                                initialValues={this.props.enquiryState}
                                                clear={this.onCancel}
                                            />
                                        </div>
                                        <div className='_form-slide'>
                                            <h3 className='_heading-5 _gold'>STEP 3: CATERING</h3>
                                            <p style={{marginTop: '0'}}>- please add to this section should your booking be more than one day</p>
                                            <CateringsForm  
                                                previous={this.previous} 
                                                onSubmit={this.onSubmitNext}
                                                initialValues={this.props.enquiryState}
                                                clear={this.onCancel}
                                            />
                                        </div>
                                        <div className='_form-slide'>
                                            <h3 className='_heading-5 _gold'>FINAL STEP: SPECIAL ASSISTANCE</h3>
                                            <p style={{marginTop: '0'}}>Although all our venues are wheelchair friendly, you are welcome to specify whether delegates would need any special assistance.</p>
                                            <SpecialAssistanceForm  
                                                previous={this.previous} 
                                                onSubmit={this.onSubmit}
                                                initialValues={this.props.enquiryState}
                                                clear={this.onCancel}
                                            />
                                        </div>
                                        {/* <div className='_form-slide'>
                                            <h3 className='_heading-5 _gold'>FINAL STEP: CONFIRM BOOKING</h3>
                                            <p style={{marginTop: '0'}}>
                                                <input type="checkbox" onChange={this.acceptTerms} checked={this.state.termsAccepted} />
                                                Before you submit you agree to our <Link to="terms-and-conditions" className='_gold' style={{textDecoration: 'underline'}}><strong>Terms & Conditions</strong></Link> by checking this box.
                                            </p>
                                            <ConfirmationForm  
                                                previous={this.previous} 
                                                onSubmit={this.onSubmit}
                                                clear={this.onCancel}
                                                termsAccepted={this.state.termsAccepted}
                                            />
                                        </div> */}
                                        <div className='_form-slide'>
                                            <h3 className='_heading-5 _gold'>SUBMITTING ENQUIRY...</h3>
                                            <p style={{marginTop: '0'}}>We are sending your booking through to our office.</p>
                                        </div>
                                    </ Slider>
                               </div>
                                
                           </div>
                        </div>
                    </div>
                </section>
            </Layout>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        enquiryState: state.enquiryState,
        submitState: state.submitEnquiryState
    }
}

export default connect(mapStateToProps, {
    collectBookingEnquiryFormValues,
    submitBookingEnquiry,
    resetForms
})(Booking)