import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import EnquireNowSection from './views/EnquireNowSection'
import Form from './forms/ContactForm'
import {
    sendContactMessage,
    resetForms
} from '../actions'
import {connect} from 'react-redux'
import history from '../history'

class Contact extends React.Component {

    onSubmit = formValues => {
        this.props.sendContactMessage(formValues)
    }

    render(){

        if(this.props.submitState.success){
            history.push('/contact/thankyou')
            this.props.resetForms()
        }

        return(
            <Layout>
                <Helmet>
                    <title>Contact Us | Graham and Rhona Beck Skills Centre</title>
                    <meta name="description" content="For more information, and to make a booking, contact Centre Coordinator Sanette Piedt on Tel: 023 626 1833 or email: enquiries@beckfamilyestates.co.za. Address: R60, Riverside/Goree Rd, Robertson, 6705" />
                    <link rel="canonical" href="https://beckfamilyconferencecentre.co.za/contact" />
                </Helmet>
                <ScrollToTop />
                <section className='_section _section-contact _section-top-no-banner'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h1 className='_heading-3 _gold'>Contact Us</h1>
                        </div>
                    </div>
                </section>
                <section className='_section _section-form'>
                    <div className='_grid'>
                        <div className='_column'>
                           <div className='_panel'>
                                <Form 
                                    onSubmit={this.onSubmit}
                                    errors={this.props.submitState.errors ? this.props.submitState.errors : null}
                                />
                                <p style={{textAlign: 'center', marginTop: '60px'}}>For more information, and to make a booking, contact Centre Coordinator Sanette Piedt on<br /> 
                                <strong className='_gold'>Tel:</strong> <a href="tel:023 626 1833">023 626 1833</a> or <strong className='_gold'>email:</strong> <a href="mailto:enquiries@beckfamilyestates.co.za">enquiries@beckfamilyestates.co.za</a>.</p>

                                <p style={{textAlign: 'center', marginBottom: '0'}}><strong className='_gold'>Address:</strong> <a href="https://goo.gl/maps/5b1R1nNMx1FKtet99" target="_blank" rel="noreferrer">R60, Riverside/Goree Rd, Robertson, 6705</a></p>
                           </div>
                        </div>
                    </div>
                </section>
                <EnquireNowSection />
            </Layout>
            
        )
    }
}

const mapStateToProps = state => {
    return {
        submitState: state.contactState
    }
}

export default connect(mapStateToProps, {
    sendContactMessage,
    resetForms
})(Contact)