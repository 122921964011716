import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import contactReducer from './contactReducer'
import newsletterSubscriptionReducer from './newsletterSubscriptionReducer'
import enquiryReducer from './enquiryReducer'
import submitEnquiryReducer from './submitEnquiryReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['enquiryState', 'submitEnquiryState']
}

const rootReducer = combineReducers({
    contactState: contactReducer,
    newsletterSubscriptionState: newsletterSubscriptionReducer,
    enquiryState: enquiryReducer,
    submitEnquiryState: submitEnquiryReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)