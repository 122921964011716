import React from 'react'
import {Link} from 'react-router-dom'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class VenueItems extends React.Component {

    renderItems = () => {
        return this.props.data.images.map((path,index) => {
            return(
                <div className='_venue-preview-image' key={index} >
                    <img src={path} alt="" />
                </div>
            )
        })
    }
    
    render(){
        if(!this.props.data){
            return <></>
        }

        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            easing: 'ease-in-out',
            pauseOnHover: false,
        };

        return(
            <div className='_grid _grid-2 _venue-item'>
                <div className='_column'>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        {this.renderItems()}
                    </ Slider>
                </div>
                <div className='_column'>
                    <div className='_content'>
                        {this.props.data.name && <h3 className='_heading-6 _gold'>{this.props.data.name}</h3>}
                        {this.props.data.description && <div className='_description'>{this.props.data.description}</div>}
                        <Link to="/make-a-booking" className="_button _button-2">Enquire</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default VenueItems