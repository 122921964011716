import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    Input,
    TextArea
} from './fields'

class ContactForm extends React.Component {

    state= {
        button: 'Send'
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.setState({
            button: 'Sending'
        })
        this.props.onSubmit(formValues)
    }

    render(){

        if(this.props.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-contact">
                <div className="_form-row">
                    <Field name="full_name" type="text" component={Input} label="Full Name*" />
                    <Field name="email" type="email" component={Input} label="Email*" />
                </div>
                <div className="_form-row">
                    <Field name="message" component={TextArea} label="Message*" />
                </div>
                <div className="_form-group" style={{marginTop: '0'}}>
                    <button className={`_button _submit-button ${this.state.button === 'Sending' ? '_sending' : ''}`}>Submit</button>
                </div>
                {
                    this.props.errors && (
                        <div className="_form-row">
                            {this.renderErrors()}
                        </div>
                    )
                }
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.email){
        errors.email = "You must enter your email address"
    }

    if(!formValues.full_name){
        errors.full_name = "You must enter your full name"
    }

    if(!formValues.message){
        errors.message = "You must enter your message"
    }

    return errors
}

export default reduxForm({
    form: 'contact',
    validate
})(ContactForm)