import React from 'react'
import {
    reduxForm
} from 'redux-form'

class ConfirmationForm extends React.Component {

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group">
                    <div className="_error-message" key={index}>{item[0]}</div>
                </div>
            )
        })
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    onCancel = () => {
        this.props.clear()
    }

    render(){

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-booking">
                <div className="_form-row _button-row">
                    <button className='_button _button-3' type='button' onClick={this.props.previous}>Back</button>
                    <button className='_button _button-3' type="button" onClick={this.onCancel}>Cancel</button>
                    {
                        this.props.termsAccepted && (
                            <button className={`_button`}>Submit Enquiry</button>
                        )
                    }
                    {
                        !this.props.termsAccepted && (
                            <button className={`_button`} type="button" style={{opacity: '.5'}}>Submit Enquiry</button>
                        )
                    }
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'confirmation',
})(ConfirmationForm)