import React from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class MasonaryGrid extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          photoIndex: 0,
          isOpen: false,
        };
    }

    renderItems = () => {
        return this.props.data.map((img, index) => {
            return (
                <div className='_mg_col' onClick={() => this.setState({ isOpen: true, photoIndex: index })}>
                    <img src={img} alt={'Skills Centre Gallery' + index} />
                </div>
            )
        })
    }

    render(){

        const { photoIndex, isOpen } = this.state;

        const {data} = this.props

        return(
            <>
                <div className="container _masonary-grid">
                    {data && this.renderItems()}
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={data[photoIndex]}
                        nextSrc={data[(photoIndex + 1) % data.length]}
                        prevSrc={data[(photoIndex + data.length - 1) % data.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                            photoIndex: (photoIndex + data.length - 1) % data.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                            photoIndex: (photoIndex + 1) % data.length,
                            })
                        }
                        animationOnKeyInput
                        imageTitle={data[photoIndex].alt ? data[photoIndex].alt : `Skills Centre Image ${photoIndex}`}
                        clickOutsideToClose
                    />
                )}
              </>
        )
    }
}

export default MasonaryGrid