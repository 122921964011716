import React from 'react'
import scrollIcon from '../../assets/img/home/scroll-icon.svg'

class ScrollDownButton extends React.Component {
    render(){
        return(
            <button className='_scroll-down-button' onClick={this.props.onClick}>
                <span>Scroll down to explore</span>
                <img src={scrollIcon} alt="" />
            </button>
        )
    }
}

export default ScrollDownButton