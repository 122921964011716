import React from 'react'
import {
    Field,
    reduxForm
} from 'redux-form'

import {
    TextArea
} from './fields'

class SpecialAssistanceForm extends React.Component {

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    onCancel = () => {
        this.props.clear()
    }

    render(){

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="_form-booking">

                <div className="_form-row">
                    <Field name="special_assistance" component={TextArea} label="SPECIAL ASSISTANCE" placeholder="Tell us more" />
                </div>
                
                <div className="_form-row _button-row">
                    <button className='_button _button-3' type='button' onClick={this.props.previous}>Back</button>
                    <button className='_button _button-3' type="button" onClick={this.onCancel}>Cancel</button>
                    <button className={`_button`}>Submit Enquiry</button>
                </div>
                
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    return errors
}

export default reduxForm({
    form: 'specialassistance',
    validate
})(SpecialAssistanceForm)