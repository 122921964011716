import React from 'react'
import {Link} from 'react-router-dom'
import logo2 from '../../assets/img/home/beck-family-logo.svg'

class ContactBlockRight extends React.Component {
    render(){
        return(
            <div className='_contact-block _contact-block-right'>
                <Link to="/" className='_logo-2'>
                    <img src={logo2} alt="Beck Family" />
                </Link>
                <a href="mailto:enquiries@beckfamilyestates.co.za" className='_link-text'>enquiries@beckfamilyestates.co.za</a>
            </div>
        )
    }
}

export default ContactBlockRight