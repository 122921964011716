import React from 'react'
import gbImage from '../../assets/img/home/enquire-now.jpg'
import {Link} from 'react-router-dom'
import { Parallax } from 'react-parallax';

class EnquireNowSection extends React.Component {
    render(){
        return(
            <Parallax  bgImage={gbImage} bgImageAlt="Need Help Enquire Now Call To Action" strength={200}>
                <section className='_section _section-general _section-enquire-now'
                    // style={{
                    //     backgroundImage: `url(${gbImage})`
                    // }}
                >
                    <div className='_grid'>
                        <div className='_column'>
                            <h2 className='_heading-5'>NEED HELP</h2>
                            <h3 className='_heading-3'>Enquire Now</h3>
                            <Link to="/make-a-booking"className="_button">Enquire</Link>
                        </div>
                    </div>
                    <div className='_overlay' />
                </section>
            </Parallax>
        )
    }
}

export default EnquireNowSection