import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import EnquireNowSection from './views/EnquireNowSection'

class NewsletterSubscriptionThankyou extends React.Component {
    render(){
        return(
            <Layout>
                <Helmet>
                    <title>Newsletter Subscription Thankyou | Graham and Rhona Beck Skills Centre</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <ScrollToTop />
                <section className='_section _section-contact _section-top-no-banner'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h1 className='_heading-3 _gold'>Subscribed</h1>
                            <p>Thank you for subscribing to our newsletter. Stay tuned for exiting stuff.</p>
                        </div>
                    </div>
                </section>

                <EnquireNowSection />
            </Layout>
        )
    }
}

export default NewsletterSubscriptionThankyou