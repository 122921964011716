import {
    COLLECT_BOOKING_ENQUIRY_FORMVALUES,
    RESET_FORMS
} from '../actions/types'

let initialState = {}

const enquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case COLLECT_BOOKING_ENQUIRY_FORMVALUES:
            let newState = {
                ...state,
                ...action.payload
            }
            return newState
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default enquiryReducer