import React from 'react'
import {Link} from 'react-router-dom'
import TopNavLeft from './TopNavLeft'
import TopNavRight from './TopNavRight'
import Logo from './Logo'
import HamburgerIcon from './HamburgerIcon'

class HeaderInitial extends React.Component {
    
    render(){
        return(
            <header className='_header _header-initial'>
                <div className='_grid'>
                    <div className='_column'>
                        <div className='_inner'>
                            <HamburgerIcon onClick={this.props.onClick} className={this.props.menuOpen ? '_open' : ''} />
                            <TopNavLeft />
                            <Logo />
                            <TopNavRight />
                            <Link to="/make-a-booking" className='_button _button-1 _call-to-action-link'>Enquire Now</Link>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderInitial