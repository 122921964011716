import React from 'react'

class ContactBlockLeft extends React.Component {
    render(){
        return(
            <div className='_contact-block _contact-block-left'>
                <a href="tel:0236261833" className='_link-text'>023 626 1833</a>
            </div>
        )
    }
}

export default ContactBlockLeft