import React from 'react'
import {Link} from 'react-router-dom'
import ContactBlockLeft from '../views/ContactBlockLeft'
import ContactBlockRight from '../views/ContactBlockRight'

class SideNav extends React.Component {
    
    render(){
        return(
            <nav className={`_nav-side ${this.props.menuOpen ? '_show' : ''}`}>
                <ul className='_nav-side-list'>
                    <li className='_nav-item'>
                        <Link to="/" className="_nav-link">HOME</Link>
                    </li>
                    <li className='_nav-item'>
                        <Link to="/facilities" className="_nav-link">FACILITIES</Link>
                    </li>
                    <li className='_nav-item'>
                        <Link to="/gallery" className="_nav-link">GALLERY</Link>
                    </li>
                    <li className='_nav-item'>
                        <Link to="/location" className="_nav-link">LOCATION</Link>
                    </li>
                    <li className='_nav-item'>
                        <Link to="/contact" className="_nav-link">CONTACT US</Link>
                    </li>
                    <li className='_nav-item' style={{paddingTop: '20px'}}>
                        <Link to="/make-a-booking" className='_button _button-1 _call-to-action-link'>Enquire Now</Link>
                    </li>
                </ul>
                <div className='_bottom-items'>
                    <div className='_grid'>
                        <ContactBlockRight />
                        <ContactBlockLeft />
                    </div>
                </div>
            </nav>
        )
    }
}

export default SideNav