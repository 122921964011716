import React from 'react'
import Layout from './layout/Layout'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import EnquireNowSection from './views/EnquireNowSection'
import Banner from './ui/Banner'
import MasonaryGrid from './ui/MasonaryGrid'

import bannerImg from '../assets/img/gallery/gallery-banner.jpg'

import img1 from '../assets/img/gallery/01@2x.png'
import img2 from '../assets/img/gallery/02@2x.png'
import img3 from '../assets/img/gallery/03@2x.png'
import img4 from '../assets/img/gallery/04@2x.png'
import img5 from '../assets/img/gallery/05@2x.png'
import img6 from '../assets/img/gallery/06@2x.png'
import img7 from '../assets/img/gallery/07@2x.png'
import img8 from '../assets/img/gallery/08@2x.png'
import img9 from '../assets/img/gallery/09@2x.png'
import img10 from '../assets/img/gallery/10@2x.png'
import img11 from '../assets/img/gallery/11@2x.png'
import img12 from '../assets/img/gallery/12@2x.png'
import img13 from '../assets/img/gallery/13@2x.png'
import img14 from '../assets/img/gallery/14@2x.png'
import img15 from '../assets/img/gallery/15@2x.png'
import img16 from '../assets/img/gallery/16@2x.png'
import img17 from '../assets/img/gallery/17@2x.png'
import img18 from '../assets/img/gallery/18@2x.png'
import img19 from '../assets/img/gallery/19@2x.png'
import img20 from '../assets/img/gallery/20@2x.png'
import img21 from '../assets/img/gallery/21@2x.png'
import img22 from '../assets/img/gallery/22@2x.png'
import img23 from '../assets/img/gallery/23@2x.png'
import img24 from '../assets/img/gallery/24@2x.png'

class Gallery extends React.Component {

    render(){

        const data = [
            img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, 
        ]

        return(
            <Layout>
                <Helmet>
                    <title>Gallery | Graham and Rhona Beck Skills Centre</title>
                    <meta name="description" content="View images of past Events here." />
                    <link rel="canonical" href="https://beckfamilyconferencecentre.co.za/gallery" />
                </Helmet>
                <ScrollToTop />
                <Banner heading="Gallery" image={bannerImg} />
                <section className='_section _section-gallery'>
                    <div className='_grid'>
                        <div className='_column'>
                            <MasonaryGrid data={data} />
                        </div>
                    </div>
                </section>
                <EnquireNowSection />
            </Layout>
            
        )
    }
}

export default Gallery