import React from 'react'

class Banner extends React.Component {
    render(){
        return(
            <section className='_section _section-top-banner' 
                style={{backgroundImage: `url(${this.props.image})`}}
            >
                {this.props.heading && (
                    <div className='_panel'>
                        <div className='_grid'>
                            <div className='_column'>
                                <h1 className='_heading-3 _gold'>{this.props.heading}</h1>
                            </div>
                        </div>
                    </div>
                )}
                <div className='_overlay' />
                
            </section>
        )
    }
}

export default Banner