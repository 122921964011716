import React from 'react'
import {Link} from 'react-router-dom'
import ScrollDownButton from '../ui/ScrollDownButton'
import bgImage from '../../assets/img/home/hero-banner.jpg'
import ContactBlockLeft from './ContactBlockLeft'
import ContactBlockRight from './ContactBlockRight'

class HeroBanner extends React.Component {
    render(){
        return(
            <section className='_section _section-hero-banner'
                style={{
                    backgroundImage: `url(${bgImage})`
                }}
            >
                <div className='_heading-main'>
                    <div className='_grid'>
                        <div className='_column'>
                            <h1 className='_heading-2'>CONFERENCING AND TRAINING</h1>
                            <h2 className='_heading-1'>In The Winelands</h2>
                            <Link to="/facilities" className='_button'>View Facilities</Link>
                        </div>
                    </div>
                </div>

                <div className='_bottom-items'>
                    <div className='_grid'>
                        <ContactBlockLeft />
                        <ContactBlockRight />
                    </div>
                </div>
                
                <ScrollDownButton onClick={this.props.scrollDown} />

                <div className='_overlay' />

            </section>
        )
    }
}

export default HeroBanner