import admin from '../apis/admin'

import {
    RESET_FORMS,
    SEND_CONTACT_MESSAGE,
    SEND_CONTACT_MESSAGE_FAILED,
    NEWSLETTER_SUBSCRIPTION,
    NEWSLETTER_SUBSCRIPTION_FAILED,
    COLLECT_BOOKING_ENQUIRY_FORMVALUES,
    SUBMIT_BOOKING_ENQUIRY,
    SUBMIT_BOOKING_ENQUIRY_FAILED
} from './types'

export const resetForms = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FORMS
        })
    }
}

export const sendContactMessage = formValues => async dispatch => {
    try {

        const response = await admin.post('contact', formValues)

        dispatch({
            type: SEND_CONTACT_MESSAGE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SEND_CONTACT_MESSAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const newsletterSubscription = formValues => async dispatch => {
    try {
        const response = await admin.post('newsletter/subscribe', formValues)

        dispatch({
            type: NEWSLETTER_SUBSCRIPTION,
            payload: response.data
        })
    } catch(error){
        dispatch({
            type: NEWSLETTER_SUBSCRIPTION_FAILED,
            payload: error.response ? error.response.data : null
        })       
    }
}

export const collectBookingEnquiryFormValues = formValues => {
    return (dispatch) => {
        dispatch({
            type: COLLECT_BOOKING_ENQUIRY_FORMVALUES,
            payload: formValues
        })
    }
}

export const submitBookingEnquiry = formValues => async dispatch => {
    try {
        const response = await admin.post('booking/enquiry', formValues)

        dispatch({
            type: SUBMIT_BOOKING_ENQUIRY,
            payload: response.data
        })

    } catch(error){

        dispatch({
            type: SUBMIT_BOOKING_ENQUIRY_FAILED,
            payload: error.response ? error.response.data : null
        })       
    }
}
