import React from 'react'
import {Link} from 'react-router-dom'

class TopNavLeft extends React.Component {
    
    render(){
        return(
            <ul className='_nav-list _nav-list-left'>
                <li className='_nav-item'>
                    <Link to="/" className="_nav-link">HOME</Link>
                </li>
                <li className='_nav-item'>
                    <Link to="/facilities" className="_nav-link">FACILITIES</Link>
                </li>
                <li className='_nav-item'>
                    <Link to="/gallery" className="_nav-link">GALLERY</Link>
                </li>
            </ul>
        )
    }
}

export default TopNavLeft