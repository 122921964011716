import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/img/home/skills-centre-logo.svg'

class Logo extends React.Component {
    
    render(){
        return(
            <Link to="/" className='_logo'>
                <img src={logo} alt="Skills Centre" />
            </Link>
        )
    }
}

export default Logo