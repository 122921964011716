import React from 'react'

class HamburgerIcon extends React.Component {
    
    render(){
        return(
            <button className={`_hamburger-icon ${this.props.className ? this.props.className : ''}`} onClick={this.props.onClick}>
                <span />
                <span />
            </button>
        )
    }
}

export default HamburgerIcon