import {
    NEWSLETTER_SUBSCRIPTION,
    NEWSLETTER_SUBSCRIPTION_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

export const newsletterSubscriptionReducer = (state = initialState, action) =>{
    switch(action.type){
        case NEWSLETTER_SUBSCRIPTION:
            return {
                success: action.payload
            }
        case NEWSLETTER_SUBSCRIPTION_FAILED:
            return {
                errors: action.payload
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default newsletterSubscriptionReducer